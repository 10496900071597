/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Select from "react-select";
import { FiClock } from "react-icons/fi";
import { VscClose } from "react-icons/vsc";
import DatePicker from "react-datepicker";
import React, { useEffect, useState, useRef } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import useCompany from "../../utils/Hook/UseCompany";
import { FormateDate } from "../../utils/FormateDate";
import useHeaders from "../../Shared/Headers/Headers";
import { ConvertDate } from "../../utils/ConvertDate";
import useUserData from "../../utils/Hook/UseUserData";
import useInstitute from "../../utils/Hook/UseInstitute";
import { ConvertToAMPM } from "../../utils/ConvertToAMPM";
import Pagination from "../../Shared/Pagination/Pagination";
import ForwardModal from "./../../Shared/Modal/ForwardModal";
import OptionSpinner from "../../Shared/Loader/OptionSpinner";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import ScheduleModal from "./../../Shared/Modal/ScheduleModal";
import NoDataFound from "../../Shared/NoDataFound/NoDataFound";
import SkelitonLoader from "../../Shared/Loader/SkelitonLoader";
import PrivateRoute from "../../Shared/PrivateRoute/PrivateRoute";
import { TbLayoutSidebarRightCollapse, TbLoader3 } from "react-icons/tb";
import { CollectionStatus } from "../../Shared/SharedData/CollectionStatus";
import SearchDropdown from "./../../Shared/SharedComponents/SearchDropDown";
import { productTypeOptions } from "../../Shared/SharedData/productTypeOptions";
import CollectionStatusChangeModal from "./../../Shared/Modal/CollectionStatusChangeModal";

import "react-datepicker/dist/react-datepicker.css";
import TableInfoSearch from "./../../utils/UI/InputSearch/TableInfoSearch";

const Collections = () => {
  const headers = useHeaders();
  const dropdownRef = useRef(null);
  const tableHeadRefs = useRef([]);
  const { userData } = useUserData();
  const [page, setPage] = useState(1);
  const { institutes } = useInstitute();
  const [mobile, setMobile] = useState();
  const [toDate, setToDate] = useState();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [fromDate, setFromDate] = useState();
  const [isReload, setIsReload] = useState(0);
  const [isActive, setIsActive] = useState(1);
  const [infoSearch, setInfoSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemarks, setIsRemarks] = useState(false);
  const [prevStatus, setPrevStatus] = useState(null);
  const [filterData, setFilteredData] = useState({});
  const [infoFilter, setInfoFilter] = useState(false);
  const [bankFilter, setBankFilter] = useState(false);
  const [salesFilter, setSalesFilter] = useState(false);
  const [showForward, setShowForward] = useState(false);
  const [paginationData, setPaginationData] = useState();
  const [collectionData, setCollectionData] = useState([]);
  const [applicationId, setApplicationId] = useState(null);
  const [companyFilter, setCompanyFilter] = useState(false);
  const [productFilter, setProductFilter] = useState(false);
  const [scheduleFilter, setScheduleFilter] = useState(false);
  const [salesUserOptions, setSalesUserOptions] = useState([]);
  const [showClearButton, setShowClearButton] = useState(false);
  const [collectionMember, setCollectionMember] = useState(null);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const { companyOptions, companyLoading, handleChangeName } = useCompany();

  const startSerial =
    (paginationData?.current_page - 1) * paginationData?.limit + 1;

  // Table Filter Options
  const [collectionStatusFilter, setCollectionStatusFilter] = useState(false);

  // Schedule State Here
  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    setFilteredData((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const handleInfoSearchChange = (e) => {
    const value = e.target.value;
    setInfoSearch(value);
    setFilteredData((prevState) => ({
      ...prevState,
      mobile: value,
    }));
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    if (date && toDate && date > toDate) {
      setError("From Date cannot be after To Date");
      setFromDate(null);
    } else {
      setError("");
    }
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    if (date && fromDate && date < fromDate) {
      setError("To Date cannot be before From Date");
      setToDate(null);
    } else {
      setError("");
    }
  };

  const handleSearch = (searchQuery, setFilter = true) => {
    setIsLoading(true);
    let params = {};

    // Handle the status based on isActive value
    if (isActive === 1) {
      params["schedule_status"] = "active";
    } else if (isActive === 2) {
      params["schedule_status"] = "upcoming";
    } else if (isActive === 3) {
      params["collection_status"] = "cancel";
    } else if (isActive === 4) {
      params["schedule-pending-status"] = "pending";
    }

    // Check search query length and user data
    if (searchQuery?.length > 3 && userData?.user) {
      setCollectionData([]);
      if (setFilter) {
        setShowClearButton(true);
      }

      if (headers?.headers?.Authorization !== "Token null") {
        // Construct query string from params
        const queryString = Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");

        axios
          .get(
            `${process.env.REACT_APP_API_PATH}/collection-applications/?query&search=${searchQuery}&${queryString}`,
            headers
          )
          .then((resp) => {
            setPaginationData(resp?.data);
            setCollectionData(resp?.data?.results);
            setIsLoading(false);
            if (!setFilter) {
              setInfoFilter(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    } else {
      SuccessAlert("Type At least 4 Digits to Search", "error");
      setIsLoading(false);
      if (setFilter) {
        setShowClearButton(false);
      }
    }
  };

  const handleSearchSubmit = () => handleSearch(mobile, true);
  const handleInfoSearchSubmit = () => handleSearch(infoSearch, false);

  const handlePageChange = async (page) => {
    setPage(page);
  };

  const handleClear = () => {
    setPage(1);
    setMobile("");
    setToDate(null);
    setFromDate(null);
    setIsLoading(true);
    setFilteredData({});
    setShowClearButton(false);
    setIsReload(isReload + 1);
  };

  //Get Sales Users Options
  useEffect(() => {
    if(headers?.headers?.Authorization !== "Token null"){
      axios
        .get(`${process.env.REACT_APP_API_PATH}/collection-users/`, headers)
        .then((resp) => {
          setSalesUserOptions(resp?.data?.results);
        });
    }
  }, []);

  const fetchData = (additionalParams = {}) => {
    setIsLoading(true);
    setCollectionData([]);

    // Build initial params, always include the `page`
    let params = { page };

    // Handle `isActive` conditions
    if (isActive === 1) {
      params["schedule_status"] = "active";
    } else if (isActive === 2) {
      params["schedule_status"] = "upcoming";
    } else if (isActive === 3) {
      // Remove schedule_status if it exists and add collection_status
      delete params["schedule_status"];
      params["collection_status"] = "cancel";
    } else if (isActive === 4) {
      // Remove schedule_status if it exists and add schedule-pending-status
      delete params["schedule_status"];
      params["schedule-pending-status"] = "pending";
    }

    // Merge additional params (like filters) after handling the isActive status
    params = { ...params, ...additionalParams };

    // API URL
    let url = `${process.env.REACT_APP_API_PATH}/collection-applications/`;

    if(headers?.headers?.Authorization !== "Token null"){
      axios
        .get(url, {
          params: params,
          headers: {
            Authorization: headers?.headers?.Authorization,
          },
        })
        .then((resp) => {
          setIsLoading(false);
          setPaginationData(resp?.data);
          setCollectionData(resp?.data?.results);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  // useEffect to trigger data fetching on page change, isActive or isReload changes
  useEffect(() => {
    let filterParams = {};

    Object.keys(filterData).forEach((key) => {
      if (Array.isArray(filterData[key])) {
        if (filterData[key].length > 0) {
          filterParams[key] = filterData[key].join(",");
        }
      } else if (filterData[key]) {
        filterParams[key] = filterData[key];
      }
    });

    fetchData(filterParams);
  }, [page, isActive, isReload]);

  // Table Filter Submit Handler
  const handleTableFilterSubmit = (e) => {
    e.preventDefault();
    let filterParams = { page: 1 };

    // Add filter data to params
    Object.keys(filterData).forEach((key) => {
      if (Array.isArray(filterData[key])) {
        if (filterData[key].length > 0) {
          filterParams[key] = filterData[key].join(",");
        }
      } else if (filterData[key]) {
        filterParams[key] = filterData[key];
      }
    });

    // Reset filters and close modals
    setPage(1);
    setInfoFilter(false);
    setBankFilter(false);
    setSalesFilter(false);
    setCompanyFilter(false);
    setProductFilter(false);
    setScheduleFilter(false);
    setCollectionStatusFilter(false);

    // Call fetchData with the filter parameters
    fetchData(filterParams);
  };

  useEffect(() => {
    const data = {
      collection_member: collectionMember,
    };
    if (applicationId) {
      try {
        axios
          .post(
            `${process.env.REACT_APP_API_PATH}/application-collection-member-assign/${applicationId}/`,
            data,
            headers
          )
          .then((resp) => {
            SuccessAlert("Successfully Added", "success");
            setIsReload(isReload + 1);
          });
      } catch (error) {
        SuccessAlert("Added Failed", "error");
      }
    }
  }, [applicationId]);

  // Handle Click Outsite For Filter Select
  const addTableHeadRef = (ref) => {
    if (ref && !tableHeadRefs.current.includes(ref)) {
      tableHeadRefs.current.push(ref);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element is within the dropdown or any of the table heads
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        tableHeadRefs.current.every((ref) => !ref.contains(event.target))
      ) {
        setInfoFilter(false);
        setCompanyFilter(false);
        setBankFilter(false);
        setProductFilter(false);
        setSalesFilter(false);
        setScheduleFilter(false);
        setCollectionStatusFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const scheduleDate = `${fromDate ? FormateDate(fromDate) : ""},${
      toDate ? FormateDate(toDate) : ""
    }`;
    if (scheduleDate?.length > 1) {
      setFilteredData({ ...filterData, schedule_date: scheduleDate });
    }
  }, [fromDate, toDate]);

  const handleReset = () => {
    setPage(1);
    setFilteredData({});
    setInfoSearch(null);
    setInfoFilter(false);
    setIsReload(isReload + 1);
  };

  const handleCompanyClear = () => {
    fetchData();
    setFilteredData({});
    setCompanyFilter(false);
  };

  return (
    <PrivateRoute>
      <div className="bg-gray-50">
        <div>
          <div className="min-h-screen px-6">
            <div className="container mx-auto pb-8">
              <div>
                <div className="flex justify-between gap-x-4 items-center pt-4 pb-1">
                  <div className="flex justify-center items-center border border-gray-300 bg-white p-1 rounded">
                    <button
                      onClick={() => {
                        setPage(1);
                        setIsActive(1);
                        setFilteredData({});
                      }}
                      className={`text-sm whitespace-nowrap border-b-2 transition-all duration-300 ease-in-out mx-4 font-medium ${
                        isActive === 1
                          ? "text-[#007BFF] border-b-[#007BFF]"
                          : "border-b-transparent hover:text-[#007BFF] hover:border-b-[#007BFF]"
                      } py-1.5 flex justify-center items-center gap-x-1 w-full`}
                    >
                      <TbLayoutSidebarRightCollapse />
                      <span>Today Collection</span>
                    </button>

                    <button
                      onClick={() => {
                        setPage(1);
                        setIsActive(2);
                        setFilteredData({});
                      }}
                      className={`text-sm whitespace-nowrap border-b-2 transition-all duration-300 ease-in-out mx-4 font-medium  ${
                        isActive === 2
                          ? "text-[#007BFF] border-b-[#007BFF]"
                          : "border-b-transparent hover:text-[#007BFF] hover:border-b-[#007BFF]"
                      } py-1.5 flex justify-center items-center gap-x-1 w-full`}
                    >
                      <TbLoader3 />
                      <span>Upcomming Collection</span>
                    </button>

                    <button
                      onClick={() => {
                        setPage(1);
                        setIsActive(3);
                        setFilteredData({});
                      }}
                      className={`text-sm whitespace-nowrap border-b-2 transition-all duration-300 ease-in-out mx-4 font-medium  ${
                        isActive === 3
                          ? "text-[#007BFF] border-b-[#007BFF]"
                          : "border-b-transparent hover:text-[#007BFF] hover:border-b-[#007BFF]"
                      } py-1.5 flex justify-center items-center gap-x-1 w-full`}
                    >
                      <VscClose />
                      <span>Cancel Collection</span>
                    </button>

                    <button
                      onClick={() => {
                        setPage(1);
                        setIsActive(4);
                        setFilteredData({});
                      }}
                      className={`text-sm whitespace-nowrap border-b-2 transition-all duration-300 ease-in-out mx-4 font-medium  ${
                        isActive === 4
                          ? "text-[#007BFF] border-b-[#007BFF]"
                          : "border-b-transparent hover:text-[#007BFF] hover:border-b-[#007BFF]"
                      } py-1.5 flex justify-center items-center gap-x-1 w-full`}
                    >
                      <TbLoader3 />
                      <span>Pending Collection</span>
                    </button>
                  </div>

                  <div className="flex items-center w-[35%]">
                    <input
                      required
                      type="text"
                      onFocus={() => setShowClearButton(false)}
                      onChange={handleMobileInputChange}
                      value={mobile}
                      placeholder="Search Here"
                      className="-mr-0.5 block w-full rounded-l-none border px-3 py-[7px] text-[13px] font-normal leading-[1.6] outline-none transition duration-300 ease-in-out focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)]"
                    />

                    {showClearButton === true ? (
                      <button
                        className="z-30 flex items-center border border-red-500 rounded-r bg-red-500 px-2 py-[9px] text-xs font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-600"
                        type="reset"
                        onClick={(e) => handleClear(e)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-4 w-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M14.293 5.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 111.414-1.414L10 8.586l4.293-4.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    ) : (
                      <button
                        className="z-30 flex items-center rounded-r bg-[#2563eb] px-2 py-[9px] text-xs font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 border border-[#2563eb]"
                        type="submit"
                        onClick={handleSearchSubmit}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-4 w-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>

                {isLoading ? (
                  <SkelitonLoader />
                ) : (
                  <div className={`font-poppins w-full relative mt-2 bg-white`}>
                    <table className="w-full leading-normal">
                      <thead>
                        <tr>
                          <th className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
                            SL
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap"
                          >
                            Department
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <div className="relative">
                              <button
                                type="button"
                                onClick={() => setInfoFilter(!infoFilter)}
                                className="w-full flex justify-center items-center gap-x-1"
                              >
                                <p>Applicant Info</p>
                                <div>
                                  {infoFilter ? (
                                    <GoTriangleUp />
                                  ) : (
                                    <GoTriangleDown />
                                  )}
                                </div>
                              </button>
                              {infoFilter && (
                                <div
                                  className="bg-white border border-gray-300 pl-1.5 pr-1 absolute z-50 top-8 left-0 shadow 2xl:w-[15vw] xl:w-[16vw] lg:w-[17vw] md:w-[24vw] w-full font-medium"
                                  ref={dropdownRef}
                                >
                                  {/* <p className="text-left text-[13px] text-gray-500 mt-2 mb-0.5">Select Company </p> */}
                                  <TableInfoSearch
                                    mobile={infoSearch}
                                    setMobile={setInfoSearch}
                                    handleReset={handleReset}
                                    placeholder="Search Here..."
                                    setOpenFilter={setInfoFilter}
                                    setShowClearButton={setShowClearButton}
                                    handleSearchSubmit={handleInfoSearchSubmit}
                                    handleMobileInputChange={
                                      handleInfoSearchChange
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <button
                              type="button"
                              onClick={() => setCompanyFilter(!companyFilter)}
                              className="w-full flex justify-center items-center gap-x-1"
                            >
                              <p>Company</p>
                              <div>
                                {companyFilter ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )}
                              </div>
                            </button>
                            {companyFilter && (
                              <div
                                className="bg-white border border-gray-300 pl-1.5 pr-1 absolute z-50 top-8 left-0 shadow 2xl:w-[15vw] xl:w-[16vw] lg:w-[17vw] md:w-[24vw] w-full font-medium"
                                ref={dropdownRef}
                              >
                                {/* <p className="text-left text-[13px] text-gray-500 mt-2 mb-0.5">Select Company </p> */}
                                <div className="w-full mb-1 mt-2">
                                  <Select
                                    isSearchable={true}
                                    isClearable={
                                      filterData?.company_name ? true : false
                                    }
                                    name="company_name"
                                    options={
                                      companyLoading
                                        ? [
                                            {
                                              label: <OptionSpinner />,
                                              value: null,
                                            },
                                          ]
                                        : companyOptions?.map((company) => ({
                                            value: company?.id,
                                            label: company?.name,
                                          }))
                                    }
                                    onChange={(selectedOption) =>
                                      setFilteredData({
                                        company_name:
                                          selectedOption?.label || null,
                                      })
                                    }
                                    defaultValue={{
                                      label:
                                        filterData?.company_name ||
                                        "Select Company",
                                      value: filterData?.company_name || null,
                                    }}
                                    className="inline-block w-full whitespace-nowrap text-left"
                                    placeholder="Company Name"
                                    onInputChange={handleChangeName}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                </div>
                                <div className="pb-2 pt-0.5 flex items-center gap-x-3">
                                  <button
                                    type="button"
                                    onClick={handleTableFilterSubmit}
                                    className="w-full text-[13px] border border-gray-300 bg-gray-100 px-5 py-1 font-normal hover:bg-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
                                  >
                                    Search
                                  </button>
                                  {filterData?.company_name ? (
                                    <button
                                      type="button"
                                      onClick={handleCompanyClear}
                                      className="w-full text-[13px] border border-red-300 bg-red-100 px-5 py-1 font-normal hover:bg-red-300 hover:border-red-400 transition-all duration-300 ease-in-out"
                                    >
                                      Reset
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => setCompanyFilter(false)}
                                      className="w-full text-[13px] border border-red-300 bg-red-100 px-5 py-1 font-normal hover:bg-red-300 hover:border-red-400 transition-all duration-300 ease-in-out"
                                    >
                                      Close
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <button
                              type="button"
                              onClick={() => setBankFilter(!bankFilter)}
                              className="w-full flex justify-center items-center gap-x-2"
                            >
                              <p>Bank</p>
                              <div>
                                {bankFilter ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )}
                              </div>
                            </button>
                            {bankFilter && (
                              <SearchDropdown
                                value="id"
                                label="name"
                                options={institutes}
                                searchProperty="institute"
                                filterData={filterData}
                                setFilteredData={setFilteredData}
                                handleTableFilterSubmit={
                                  handleTableFilterSubmit
                                }
                                setOpenFilter={setBankFilter}
                              />
                            )}
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <button
                              type="button"
                              onClick={() => setProductFilter(!productFilter)}
                              className="w-full flex justify-center items-center gap-x-2"
                            >
                              <p>Product</p>
                              <div>
                                {productFilter ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )}
                              </div>
                            </button>
                            {productFilter && (
                              <SearchDropdown
                                value="value"
                                label="name"
                                options={productTypeOptions}
                                searchProperty="application_type"
                                filterData={filterData}
                                setFilteredData={setFilteredData}
                                handleTableFilterSubmit={
                                  handleTableFilterSubmit
                                }
                                setOpenFilter={setProductFilter}
                              />
                            )}
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <button
                              type="button"
                              onClick={() => setSalesFilter(!salesFilter)}
                              className="w-full flex justify-center items-center gap-x-2"
                            >
                              <p>Collection Member</p>
                              <div>
                                {salesFilter ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )}
                              </div>
                            </button>
                            {salesFilter && (
                              <SearchDropdown
                                value="user_id"
                                label="username"
                                options={salesUserOptions}
                                searchProperty="collection_member"
                                filterData={filterData}
                                setFilteredData={setFilteredData}
                                handleTableFilterSubmit={
                                  handleTableFilterSubmit
                                }
                                setOpenFilter={setSalesFilter}
                              />
                            )}
                          </th>

                          <th className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
                            Sales Number
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <button
                              type="button"
                              onClick={() => setScheduleFilter(!scheduleFilter)}
                              className="w-full flex justify-center items-center gap-x-2"
                            >
                              <p className="font-medium text-center">
                                Schedule
                              </p>
                              <div>
                                {scheduleFilter ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )}
                              </div>
                            </button>
                            {scheduleFilter && (
                              <div
                                className="bg-white border border-gray-300 px-2 absolute z-50 top-8 left-0 2xl:w-[11vw] xl:w-[16vw] lg:w-[17vw] md:w-[24vw] w-full font-medium"
                                ref={dropdownRef}
                              >
                                <div className="w-full mt-2">
                                  <p className="text-left text-[13px] text-gray-500">
                                    From Date
                                  </p>
                                  <DatePicker
                                    selected={fromDate}
                                    onChange={handleFromDateChange}
                                    dateFormat="dd-MMM-yyyy"
                                    className="pl-3 pr-1 py-1.5 border border-gray-300 w-full focus:outline-[#01a7ff]"
                                    popperClassName="w-auto"
                                    placeholderText="From Date"
                                    // onFocus={()=>setClearButton(false)}
                                  />
                                </div>
                                <div className="mt-1">
                                  <p className="text-left text-[13px] text-gray-500">
                                    To Date
                                  </p>
                                  <DatePicker
                                    selected={toDate}
                                    onChange={handleToDateChange}
                                    dateFormat="dd-MMM-yyyy"
                                    className="pl-3 pr-1 py-1.5 border border-gray-300 w-full focus:outline-[#01a7ff]"
                                    popperClassName="w-auto"
                                    placeholderText="To Date"
                                    // onFocus={()=>setClearButton(false)}
                                  />
                                </div>
                                <div className="pb-2 pt-2">
                                  <button
                                    type="button"
                                    onClick={handleTableFilterSubmit}
                                    className="w-full text-[13px] border border-gray-300 bg-gray-100 px-5 py-1 font-normal hover:bg-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            )}
                          </th>

                          <th
                            ref={(ref) => addTableHeadRef(ref)}
                            className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap relative"
                          >
                            <button
                              type="button"
                              onClick={() =>
                                setCollectionStatusFilter(
                                  !collectionStatusFilter
                                )
                              }
                              className="w-full flex justify-center items-center gap-x-2"
                            >
                              <p>Collection Status</p>
                              <div>
                                {salesFilter ? (
                                  <GoTriangleUp />
                                ) : (
                                  <GoTriangleDown />
                                )}
                              </div>
                            </button>
                            {collectionStatusFilter && (
                              <SearchDropdown
                                value="value"
                                label="label"
                                options={CollectionStatus}
                                searchProperty="collection_status"
                                filterData={filterData}
                                setFilteredData={setFilteredData}
                                handleTableFilterSubmit={
                                  handleTableFilterSubmit
                                }
                                setOpenFilter={setCollectionStatusFilter}
                              />
                            )}
                          </th>

                          <th className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
                            Customer Remarks
                          </th>

                          <th className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
                            Collection remarks
                          </th>

                          <th className="py-1.5 px-1 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {collectionData?.length > 0 ? (
                        <tbody>
                          {collectionData?.map((item, index) => (
                            <tr key={index + 1}>
                              <td
                                className={`pl-3 pr-2 py-2 border text-[13px] text-center`}
                              >
                                <p className="text-gray-600 whitespace-nowrap">
                                  {index + startSerial}
                                </p>
                              </td>

                              <td
                                className={`p-2 border text-[13px] text-center text-gray-900 whitespace-nowrap`}
                              >
                                <p>{item?.source_department || "N/A"}</p>
                              </td>

                              <td
                                className={`p-2 border text-[13px] text-center text-gray-900 whitespace-nowrap`}
                              >
                                <p>{item?.name || "N/A"}</p>
                                <p>{item?.mobile || "N/A"}</p>
                              </td>

                              {/*    <td
                              className={`p-2 border text-[13px] text-center text-gray-900 whitespace-nowrap`}
                            >
                              <p>{item?.assign_member?.name || "N/A"}</p>
                            </td> */}

                              <td
                                className={`p-2 border text-[13px] text-center`}
                              >
                                <p className="text-gray-700 w-44">
                                  {item?.company || "N/A"}
                                </p>
                              </td>

                              <td
                                className={`relative p-2 border text-[13px] text-center`}
                              >
                                <p className="text-gray-700 whitespace-nowrap">
                                  <p>{item?.institute?.name || "N/A"}</p>
                                </p>
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center`}
                              >
                                <p className="text-gray-700 whitespace-nowrap">
                                  {item?.application_type?.name || "N/A"}
                                </p>
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-left`}
                              >
                                <Select
                                  isSearchable={false}
                                  onChange={(selectedOption) => {
                                    setCollectionMember(selectedOption?.value);
                                    setApplicationId(item?.id);
                                  }}
                                  name="sales"
                                  id="sales"
                                  placeholder="Select User"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  options={salesUserOptions?.map((option) => ({
                                    label: option?.username,
                                    value: option?.user_id,
                                  }))}
                                  defaultValue={
                                    item?.collection_member
                                      ? {
                                          label: item?.collection_member?.name,
                                          value: item?.collection_member?.id,
                                        }
                                      : {
                                          label: "Select Member",
                                          value: "Select Member",
                                        }
                                  }
                                />
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center`}
                              >
                                <p className="text-gray-700 whitespace-nowrap">
                                  {item?.sales_number || "N/A"}
                                </p>
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center relative`}
                              >
                                <div className="text-gray-700 whitespace-nowrap">
                                  <p className="text-[13px] whitespace-nowrap">
                                    {item?.schedule?.date
                                      ? ConvertDate(item?.schedule?.date)
                                      : "N/A"}
                                  </p>
                                  {item?.schedule?.time && (
                                    <p className="text-[13px] whitespace-nowrap">
                                      (
                                      {item?.schedule?.time &&
                                        ConvertToAMPM(item?.schedule?.time)}
                                      )
                                    </p>
                                  )}
                                </div>
                                <div className="absolute -top-3 right-0.5">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setPrevStatus(item);
                                      setOpenScheduleModal(true);
                                    }}
                                  >
                                    <div className="text-[#2563eb] hover:text-white bg-white hover:bg-[#2563eb] mt-3 p-1 flex items-center gap-x-1 duration-300 rounded-full">
                                      <FiClock className="text-lg" />
                                    </div>
                                  </button>
                                </div>
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center relative`}
                              >
                                <div className="absolute top-1 right-1">
                                  <p className="bg-red-50 border border-red-500 px-1 rounded-full text-[11px]">
                                    {item?.collection_pending_status_count}
                                  </p>
                                </div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setPrevStatus(item);
                                    setShowStatusChangeModal(true);
                                    setIsRemarks(true);
                                  }}
                                  className="text-gray-700 border border-sky-300 px-3.5 bg-white py-0.5 rounded-full hover:bg-[#01a7ff] hover:text-white hover:border-[#01a7ff] transition-all duration-300 ease-in-out text-[13px]"
                                >
                                  <span className="whitespace-nowrap">
                                    {item?.collection_status || "N/A"}
                                  </span>
                                </button>
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center`}
                              >
                                {item?.collection_message || "N/A"}
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center`}
                              >
                                {item?.last_collection_message || "N/A"}
                              </td>

                              <td
                                className={`px-1 py-2 border text-[13px] text-center`}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setPrevStatus(item);
                                    setShowForward(true);
                                  }}
                                  className="text-sky-600 hover:text-white bg-white hover:bg-[#01a7ff] h-6 w-8 mx-auto flex justify-center items-center gap-x-1 border border-[#01a7ff] rounded-[2px] duration-300"
                                  title="Forward"
                                >
                                  <RiShareForwardFill className="text-[14px]" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="18" className="text-center py-10">
                              <NoDataFound />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                )}
              </div>
              <div className="flex justify-end items-center relative bottom-0 w-full pb-5 -mt-3">
                {collectionData?.length > 0 && (
                  <Pagination
                    paginationData={paginationData}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
            {openScheduleModal && (
              <ScheduleModal
                currentSchedule={prevStatus}
                scheduleData={collectionData}
                setScheduleData={setCollectionData}
                setOpenScheduleModal={setOpenScheduleModal}
              />
            )}
            {showStatusChangeModal && (
              <CollectionStatusChangeModal
                isRemarks={isRemarks}
                prevStatus={prevStatus}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                setShowStatusChangeModal={setShowStatusChangeModal}
              />
            )}
            {showForward && (
              <ForwardModal
                prevStatus={prevStatus}
                setShowForward={setShowForward}
              />
            )}
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default Collections;
