import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <div>
      <footer className="bg-gray-700">
        <div className="w-full max-w-screen-xl mx-auto px-4 py-8">
          <div className="flex items-center justify-between">
            <img
              src="/assets/images/logo.png"
              loading="lazy"
              className="xl:w-40 lg:w-40 w-32"
              alt="Logo"
            />
          </div>
          <span className="block text-sm text-gray-400 text-center">
            © {currentYear}{" "}
            <a href="https://www.aamartaka.com" className="hover:underline">
              Aamartaka
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
