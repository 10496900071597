import React from "react";

const Pagination = ({ paginationData, onPageChange }) => {
  const { current_page, last_page } = paginationData || 1;

  if (!paginationData || last_page <= 1) {
    return null;
  }
/*   const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= last_page; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`px-4 py-[3px] rounded mx-1 ${
            current_page === i
              ? "bg-[#01a7ff] text-white"
              : "bg-white border border-gray-300 text-gray-700 hover:bg-sky-200 hover:border-sky-200 hover:text-sky-800 transition-all duration-300 ease-in-out"
          }`}
        >
          {i}
        </button>
      );
    }
    return pages;
  }; */

  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5; // Maximum number of visible page buttons
    const ellipsis = (
      <span key="ellipsis" className="px-2">
        ...
      </span>
    );
  
    if (last_page <= maxVisiblePages) {
      // If total pages are less than or equal to max visible pages, show all pages
      for (let i = 1; i <= last_page; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={`px-2 py-[3px] rounded-[2px] mx-1 text-sm ${
              current_page === i
                ? "bg-[#01a7ff] text-white border border-[#01a7ff]"
                : "bg-white border border-gray-300 text-gray-700 hover:bg-sky-200 hover:border-sky-200 hover:text-sky-800 transition-all duration-300 ease-in-out"
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show pages relative to current_page
      const halfVisiblePages = Math.floor(maxVisiblePages / 2);
      const startPage = Math.max(1, current_page - halfVisiblePages);
      const endPage = Math.min(last_page, startPage + maxVisiblePages - 1);
  
      if (startPage > 1) {
        pages.push(ellipsis);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={`px-2 py-[3px] rounded-[2px] mx-1 text-sm ${
              current_page === i
                ? "bg-[#01a7ff] text-white border border-[#01a7ff]"
                : "bg-white border border-gray-300 text-gray-700 hover:bg-sky-200 hover:border-sky-200 hover:text-sky-800 transition-all duration-300 ease-in-out"
            }`}
          >
            {i}
          </button>
        );
      }
  
      if (endPage < last_page) {
        pages.push(ellipsis);
      }
    }
  
    return pages;
  };

  return (
    <div className="flex justify-center mt-4">
      {current_page > 1 && (
        <button
          onClick={() => onPageChange(current_page - 1)}
          className="block py-[3px] px-1.5 leading-tight text-gray-500 bg-white border border-gray-300 hover:border-[#01a7ff] hover:text-white rounded-[2px] hover:bg-[#01a7ff] transition-all duration-300 ease-in-out"
        >
          <svg
            className="w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}

      {renderPagination()}

      {current_page < last_page && (
        <button
          onClick={() => onPageChange(current_page + 1)}
          className="block py-[3px] px-1.5 leading-tight text-gray-500 bg-white border border-gray-300 hover:border-[#01a7ff] hover:text-white rounded-[2px] hover:bg-[#01a7ff] transition-all duration-300 ease-in-out"
        >
          <svg
            className="w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Pagination;
