import React from "react";

const SkelitonLoader = () => {
  return (
    <div className="lg:px-0 md:px-0 px-2">
      <div className="h-10 bg-gray-200 mt-3 mb-6 rounded"></div>
      <div className="h-10 bg-gray-300 mb-6 rounded"></div>
      <div className="h-10 bg-gray-200 mb-6 rounded"></div>
      <div className="h-10 bg-gray-300 mb-6 rounded"></div>
      <div className="h-10 bg-gray-200 mb-6 rounded"></div>
    </div>
  );
};

export default SkelitonLoader;
