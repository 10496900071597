export const ConvertToAMPM=(timeStr)=> {
  // Regular expression to match the "HH:MM:SS - HH:MM:SS" format
  const regex = /^\d{2}:\d{2}:\d{2} - \d{2}:\d{2}:\d{2}$/;

  // Check if the input matches the expected format
  if (!regex.test(timeStr)) {
    return timeStr; // Return the original string if it doesn't match
  }

  const [startTimeStr, endTimeStr] = timeStr.split(" - ");

  // Helper function to convert "HH:MM:SS" to "HH:MM AM/PM"
  function formatTime(timeStr) {
    const [hours, minutes] = timeStr.split(':');
    const hourInt = parseInt(hours);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    const hour12 = hourInt % 12 || 12; // Convert 24-hour format to 12-hour format
    return `${hour12}:${minutes} ${ampm}`;
  }

  const formattedStartTime = formatTime(startTimeStr);
  const formattedEndTime = formatTime(endTimeStr);

  return `${formattedStartTime} - ${formattedEndTime}`;
}