import React, { useEffect } from "react";

const Loader = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div
      style={{
        height: "85vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FFFFFF",
      }}
    >
      <svg
        id="L1"
        x="0px"
        y="0px"
        version="1.1"
        xmlSpace="preserve"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 100 100"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ width: "100px", height: "100px" }}
      >
        <circle
          r="47"
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="3"
          stroke="#6082B6"
          strokeMiterlimit="15"
          strokeDasharray="12.2472,12.2472"
        >
          <animateTransform
            dur="4s"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            attributeType="XML"
            repeatCount="indefinite"
            attributeName="transform"
          />
        </circle>
        <circle
          r="39"
          cx="50"
          cy="50"
          fill="none"
          strokeWidth="2"
          stroke="#49C2EF"
          strokeMiterlimit="10"
          strokeDasharray="10,10"
        >
          <animateTransform
            dur="4s"
            type="rotate"
            from="0 50 50"
            to="-360 50 50"
            attributeType="XML"
            repeatCount="indefinite"
            attributeName="transform"
          />
        </circle>
        <foreignObject x="15" y="15" width="70" height="70">
          <img
            alt="Logo"
            className="logo-img p-0.5"
            src={"/assets/icon/logo512.png"}
          />
        </foreignObject>
      </svg>
    </div>
  );
};

export default Loader;
