/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { VscChromeClose } from "react-icons/vsc";

const SearchDropdown = ({
  value,
  label,
  options,
  filterData,
  setOpenFilter,
  searchProperty,
  setFilteredData,
  handleTableFilterSubmit,
}) => {
  const dropdownInnerRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleCheckboxChange = (item, checkBoxName) => {    
    setFilteredData((prevData) => {
      // If the checkBoxName is "bank_status", set item.id
      if (checkBoxName === "institute" || checkBoxName === "assign_member" || checkBoxName === "agent") {
        return {
          ...prevData,
          [checkBoxName]: (prevData[checkBoxName] || []).includes(item?.id)
            ? prevData[checkBoxName].filter((value) => value !== item?.id) // Remove the id if already present
            : [...(prevData[checkBoxName] || []), item?.id], // Add the id if not present
        };
      }
      if (checkBoxName === "collection_member") {
        return {
          ...prevData,
          [checkBoxName]: (prevData[checkBoxName] || []).includes(item?.user_id)
            ? prevData[checkBoxName].filter((value) => value !== item?.user_id) // Remove the id if already present
            : [...(prevData[checkBoxName] || []), item?.user_id], // Add the id if not present
        };
      }

      // For other checkBoxNames, use the existing logic
      return {
        ...prevData,
        [checkBoxName]: (prevData[checkBoxName] || [])?.includes(item?.value)
          ? prevData[checkBoxName]?.filter((value) => value !== item?.value) // Remove the value if already present
          : [...(prevData[checkBoxName] || []), item?.value], // Add the value if not present
      };
    });
  };

  const handleSelectAll = (checkBoxName, mappingData) => {
    setFilteredData((prevData) => ({
      ...prevData,
      [checkBoxName]:
        checkBoxName === "institute" || 
        checkBoxName === "bank_status" || 
        checkBoxName === "agent"
          ? mappingData?.map((item) => item?.id)
          : checkBoxName === "collection_member"
          ? mappingData?.map((item) => item?.user_id)
          : mappingData?.map((item) => item?.value),
    }));
  };
  

  const handleCancelAll = (checkBoxName) => {
    setFilteredData((prevData) => {
      const newData = { ...prevData };
      delete newData[checkBoxName];
      return newData;
    });
  };

  const handleSearchOptions = (searchText) => {
    if (searchText) {
      setFilteredOptions(
        options?.filter((option) =>
          option?.[label]?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredOptions(options);
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownInnerRef.current &&
      !dropdownInnerRef.current.contains(event.target)
    ) {
      setOpenFilter(null);
      setSearchText("");
      handleCancelAll(searchProperty);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="bg-white border border-gray-300 pl-1.5 pr-1 absolute z-50 top-9 left-0 shadow min-w-[13vw] max-h-[50vh] overflow-y-auto"
      ref={dropdownInnerRef}
    >
      <div className="pt-2 border-b pb-2 relative">
        <input
          type="text"
          className="w-full border border-gray-300 rounded-[1px] py-1 pl-3 pr-1 text-[13px] focus:outline-none focus:border-[#01a7ff]"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => {
            handleSearchOptions(e.target.value);
            setSearchText(e.target.value);
          }}
        />
        {searchText && (
          <button
            type="button"
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
            onClick={() => {
              handleSearchOptions("");
              setSearchText("");
            }}
          >
            <VscChromeClose className="text-red-500 hover:text-red-600" />
          </button>
        )}
      </div>
      <div className="divide-y">
        {filterData?.[searchProperty]?.length === options?.length ? (
          <div className="flex items-start space-x-2 py-2.5">
            <input
              className="border-gray-300 rounded h-4 w-4 cursor-pointer"
              id="cancel-all"
              type="checkbox"
              name="cancel-all"
              onChange={() => handleCancelAll(searchProperty)}
              checked={filterData?.[searchProperty]?.length === options?.length}
            />
            <label
              className="text-gray-700 font-medium leading-none cursor-pointer text-[13px]"
              htmlFor="cancel-all"
            >
              Cancel All
            </label>
          </div>
        ) : (
          <div className="flex items-start space-x-2 py-2.5">
            <input
              className="border-gray-300 rounded h-4 w-4 cursor-pointer"
              id="select-all"
              type="checkbox"
              name="select-all"
              onChange={() => handleSelectAll(searchProperty, options)}
              checked={filterData?.[searchProperty]?.length === options?.length}
            />
            <label
              className="text-gray-700 font-medium leading-none cursor-pointer text-[13px]"
              htmlFor="select-all"
            >
              Select All
            </label>
          </div>
        )}
        {filteredOptions?.map((item, index) => (
          <div className="flex items-start space-x-2 py-2.5" key={index + 1}>
            <input
              className="border-gray-300 rounded h-4 w-4 cursor-pointer"
              id={index + 1}
              type="checkbox"
              name="product"
              onChange={() => handleCheckboxChange(item, searchProperty)}
              // checked={filterData?.[searchProperty]?.includes(item?.[value])}
              checked={
                searchProperty === "application_type"
                  ? filterData?.[searchProperty]?.includes(item?.value)
                  : filterData?.[searchProperty]?.includes(item?.[value])
              }
            />
            <label
              className="text-gray-700 font-medium leading-none cursor-pointer text-[13px]"
              htmlFor={index + 1}
            >
              {item[label]}
            </label>
          </div>
        ))}
      </div>
      <div className="pb-2 pt-0.5">
        <div className="flex items-center gap-x-2">
          <button
            type="button"
            onClick={handleTableFilterSubmit}
            className="w-full text-[13px] border border-gray-300 bg-gray-100 py-0.5 font-normal hover:bg-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
          >
            Search
          </button>
          <button
            type="button"
            onClick={() => {
              setOpenFilter(null);
              setSearchText("");
              handleCancelAll(searchProperty);
            }}
            className="w-full text-[13px] border border-gray-300 bg-gray-100 py-0.5 font-normal hover:bg-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;
