import React from "react";

const OptionSpinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="w-6 h-6 rounded-full animate-spin border-[3px] border-solid border-[#2563eb] border-t-transparent"></div>
    </div>
  );
};

export default OptionSpinner;
