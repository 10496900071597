/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import useHeaders from './../Headers/Headers';
import React,{useState,useEffect} from "react";
import { SuccessAlert } from './../Alert/SuccessAlert';

const CollectionUpdateHistoryTableSubmitted = ({ updateHistoryData }) => {
  const headers=useHeaders();
  const [statusId,setStatusId]=useState(null);
  const [selectedStatus,setSelectedStatus]=useState(null);
  const statusData = [
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
  ];

  useEffect(() => {
    if (statusId) {
      const data = {
        status: selectedStatus,
      };
       axios.patch(
        `${process.env.REACT_APP_API_PATH}/application-collection-status/${statusId}/`,
        data,
        headers
      )
      .then((resp)=>{
        SuccessAlert("Status Updated", "success");
      })
      .catch((error)=>{
        SuccessAlert("Update Failed", "error");
      })
     
    } 
  }, [selectedStatus]);

  return (
    <div>
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th className="p-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
              Date
            </th>
            <th className="p-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
              Collection Status
            </th>
            <th className="p-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
              Status
            </th>
            <th className="p-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
              Remarks
            </th>
            <th className="p-2 border border-gray-200 bg-gray-100 text-center text-[13px] font-medium text-gray-700 tracking-tighter whitespace-nowrap">
              Created By
            </th>
          </tr>
        </thead>
        <tbody>
          {updateHistoryData?.map((data, index) => (
            <tr key={index + 1}>
              {data?.created_at && (
                <td className="px-2 py-1.5 border border-gray-200 bg-white text-[13px] text-center">
                  <p className="text-gray-600 whitespace-nowrap">
                    {data?.created_at}
                  </p>
                </td>
              )}

              <td className="px-2 py-1.5 border border-gray-200 bg-white text-[13px] text-center">
                <p className="text-gray-900 whitespace-nowrap">
                  {data?.collection_type}
                </p>
              </td>

              <td className="px-2 py-1.5 border-b border-b-gray-300 bg-white text-[13px] text-center flex justify-center">
                <select
                  className="block px-2 py-0.5 border border-gray-300 rounded-none bg-white text-gray-700 leading-tight focus:outline-none focus:border-blue-500 text-left text-[13px]"
                  defaultValue={data?.status}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    setStatusId(data?.id);
                  }}
                >
                  {statusData?.map((data, index) => (
                    <option
                      value={data?.status}
                      key={index + 1}
                    >
                      {data?.label}
                    </option>
                  ))}
                </select>
              </td>

              <td className="px-2 py-1.5 border border-gray-200 bg-white text-[13px] text-center">
                <p className="text-gray-900 whitespace-nowrap">
                  {data?.message}
                </p>
              </td>

              <td className="px-2 py-1.5 border border-gray-200 bg-white text-[13px] text-center">
                <p className="text-gray-900 whitespace-nowrap">
                  {data?.created_by?.name}
                </p>
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CollectionUpdateHistoryTableSubmitted;
