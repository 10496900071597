/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Select from "react-select";
import { VscClose } from "react-icons/vsc";
import { FaAngleRight } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import useHeaders from './../Headers/Headers';
import MonoLoader from './../Loader/MonoLoader';
import React, { useState, useEffect } from "react";
import OptionSpinner from './../Loader/OptionSpinner';
import { SuccessAlert } from './../Alert/SuccessAlert';
import { ConvertToAMPM } from './../../utils/ConvertToAMPM';
import { FormateDateWithMonth } from './../../utils/FormateDateWithMonth';


export default function ScheduleModal({
  pathName,
  targetType,
  scheduleData,
  setScheduleData,
  currentSchedule,
  setOpenScheduleModal,
  isApplicationDetails,
}) {
  const headers = useHeaders();
  const [isLoading, setIsLoading] = useState(true);
  const [dateOptions, setDateOptions] = useState();
  const [timeOptions, setTimeOptions] = useState();
  const [timeLoading, setTimeLoading] = useState(true);
  const [selectedTime, setSelectedTime] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(getTodayFormatted());

  function getTodayFormatted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Get All Date OPtions
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/schedule-dates/`,
        headers
      )
      .then((resp) => {
        setDateOptions(resp?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  // Get All Slot Options
  useEffect(() => {
    if (selectedDate) {
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/slot/available/?date=${selectedDate}`,
          headers
        )
        .then((resp) => {
          setTimeOptions(resp?.data?.results);
          setTimeLoading(false);
        })
        .catch((error) => {
          setTimeLoading(false);
        });
    }
  }, [selectedDate]);

  const handleDayClick = (selectedOption) => {
    setSelectedDate(selectedOption?.value);
  };

  const handleSetSchedule = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    const data = {
      date: selectedDate,
      slot: selectedTime?.value,
      target_id: currentSchedule?.id,
      target_type: targetType,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/schedule/`,
        data,
        headers
      );
      if (response) {
        setSubmitLoading(false);
        SuccessAlert("Schedule Added", "success");
        setOpenScheduleModal(false);

        if (isApplicationDetails) {
          const updatedData = scheduleData?.map((data) =>
            data?.id === response?.data?.target_id
              ? {
                  ...data,
                  current_schedule: {
                    date: response?.data?.date,
                    time: response?.data?.slot_name,
                  },
                }
              : data
          );
          setScheduleData(updatedData);
        } else {
          if (pathName === "bank_submitted") {
            const updatedData = scheduleData?.map((data) =>
              data?.["Application ID"] === response?.data?.target_id
                ? {
                    ...data,
                    Schedule: {
                      date: response?.data?.date,
                      time: response?.data?.slot_name,
                    },
                  }
                : data
            );
            setScheduleData(updatedData);
          } else {
            const updatedData = scheduleData?.map((data) =>
              data?.id === response?.data?.target_id
                ? {
                    ...data,
                    schedule: {
                      date: response?.data?.date,
                      time: response?.data?.slot_name,
                    },
                  }
                : data
            );
            setScheduleData(updatedData);
          }
        }
      }
    } catch (error) {
      SuccessAlert("Schedule Add Failed", "error");
      setSubmitLoading(false);
    }
  };

  const options = dateOptions
    ? Object.keys(dateOptions)?.map((date) => ({
        value: date,
        label: `${dateOptions[date].day} (${dateOptions[date].week})`,
      }))
    : [];

  useEffect(() => {
    document.body.classList.add("stop-scroll");
    return () => {
      document.body.classList.remove("stop-scroll");
    };
  }, []);

  return (
    <div>
      <div className="justify-center items-center flex fixed top-0 inset-1 z-50">
        <div
          className={`h-auto w-full my-6 mx-auto ${
            isMobile ? "max-w-3xl" : "max-w-4xl"
          }`}
        >
          {/*content*/}
          <div
            className={`border-0 rounded-lg shadow flex flex-col w-full bg-white zoom-from-center`}
          >
            {/*header*/}
            <div
              className={`py-2 bg-[#40474d] px-4 text-white flex items-center justify-between`}
            >
              <p
                className={`${
                  isMobile ? "text-lg" : "text-xl font-semibold"
                } text-white`}
              >
                Set Schedule
              </p>
              <button
                className="ml-auto duration-700 float-right text-2xl leading-none font-semibold"
                onClick={() => setOpenScheduleModal(false)}
              >
                <VscClose className="text-white" />
              </button>
            </div>
            {/*body*/}
            <div className={`${isMobile ? "px-2 pb-5" : "p-4"} bg-gray-100`}>
              {isLoading || timeLoading ? (
                <MonoLoader />
              ) : (
                <form
                  onSubmit={handleSetSchedule}
                  className={`bg-white rounded border mt-4 shadow ${
                    isMobile ? "px-3 py-1" : "px-5 pb-4 pt-1"
                  }`}
                >
                  <div
                    className={`${
                      isMobile
                        ? "block"
                        : "flex justify-between items-center gap-x-4"
                    }`}
                  >
                    <div className={`${isMobile ? "w-full" : "w-1/2"}`}>
                      <label className="block mt-2 mb-0.5 text-gray-600 text-[13px]">
                        Select Schedule Date
                      </label>
                      <Select
                        required
                        isSearchable={false}
                        placeholder="Select Day"
                        className="text-[13px] text-black w-full whitespace-nowrap"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        // styles={CustomStyles2}
                        options={options}
                        defaultValue={{
                          label: currentSchedule?.schedule?.date
                            ? FormateDateWithMonth(
                                currentSchedule?.schedule?.date
                              )
                            : "Schedule Date",
                          value: currentSchedule?.schedule?.date
                            ? FormateDateWithMonth(
                                currentSchedule?.schedule?.date
                              )
                            : "Select Schedule Date",
                        }}
                        onChange={handleDayClick}
                      />
                    </div>
                    <div className={`${isMobile ? "w-full" : "w-1/2"}`}>
                      <label className="block mt-2 mb-0.5 text-gray-600 text-[13px]">
                        Select Schedule Time
                      </label>
                      <Select
                        required
                        isSearchable={false}
                        placeholder="Select Time"
                        className="text-[13px] text-black w-full whitespace-nowrap"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        // styles={CustomStyles2}
                        options={timeOptions?.map((time) => ({
                          label: `${time?.start_time} - ${time?.end_time}`,
                          value: time?.id,
                        }))}
                        defaultValue={{
                          label: currentSchedule?.schedule?.time
                            ? ConvertToAMPM(currentSchedule?.schedule?.time)
                            : "Select Schedule Time",
                          value: currentSchedule?.schedule?.time
                            ? ConvertToAMPM(currentSchedule?.schedule?.time)
                            : "Select Schedule Time",
                        }}
                        onChange={(options) => setSelectedTime(options)}
                      />
                    </div>
                  </div>

                  <div
                    className={`w-full flex justify-end mt-2 ${
                      isMobile ? "pb-2" : ""
                    }`}
                  >
                    {submitLoading ? (
                      <button
                        disabled
                        type="button"
                        className={`w-36 flex justify-center items-center py-2 px-4 bg-white border border-gray-200 mt-2 ${
                          isMobile ? "text-sm" : "text-sm"
                        }`}
                      >
                        <OptionSpinner />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`flex items-center py-2 px-4 bg-[#01a7ff] border border-[#01a7ff] text-white hover:bg-white hover:text-sky-600 transition-all duration-300 ease-in-out mt-1.5 text-[13px]`}
                      >
                        <span>Set Schedule</span>
                        <FaAngleRight />
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
