/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import useHeaders from './../../Shared/Headers/Headers';
import useUserData from './UseUserData';

const useCompany = () => {
  const headers = useHeaders();
  const { userData } = useUserData();
  const [searchName, setSearchName] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);

  const handleChangeName = (e) => {
    setSearchName(e);
  };
  useEffect(() => {
    setCompanyLoading(true);
    if (userData?.user) {
      axios
        .get(`${process.env.REACT_APP_API_PATH}/company/`, headers)
        .then((res) => {
          setCompanyOptions(res?.data?.results);
          setCompanyLoading(false);
        })
        .catch((error) => {
          setCompanyLoading(false);
        });
    }
  }, [userData?.user]);

  useEffect(() => {
    if (headers?.headers?.Authorization !== "Token null") {
      try {
        fetch(
          `${process.env.REACT_APP_API_PATH}/company/${
            searchName && `?search=${searchName}`
          }`,
          headers
        )
          .then((response) => response.json())
          .then((res) => {
            const rest = res.results;
            setCompanyOptions(rest);
          });
      } catch (error) {}
    }
  }, [searchName]);
  return {
    companyOptions,
    setCompanyOptions,
    companyLoading,
    handleChangeName,
  };
};

export default useCompany;
