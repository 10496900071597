import Swal from "sweetalert2";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { HiLogout } from "react-icons/hi";
// import { useData } from "../../utils/Context/DataContext";
import { Link } from "react-router-dom"; // For routing
import { SuccessAlert } from './../Alert/SuccessAlert';

const Navbar = ({updateUser,setUpdateUser}) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const dropdownRef = useRef(null);

  const getUserDataFromLocalStorage = () => {
    const userDataStr = localStorage.getItem("salesUser");
    if (userDataStr) {
      const userDataObj = JSON.parse(userDataStr);
      setUserData(userDataObj);
    } else {
      setUserData({});
    }
  };

  useEffect(() => {
    getUserDataFromLocalStorage();
  }, [updateUser]);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("salesUser");
        localStorage.removeItem("token");
        localStorage.removeItem("myData");
        SuccessAlert("Logout Successful", "success");
        setUpdateUser(updateUser + 1);
        // setIsReload(isReload + 1);
        setOpenMenu(false);
        navigate("/");
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = () => {
    setOpenMenu(false);
  };

  return (
    <div>
      <nav className="w-full z-40 border-b">
        <div className="container mx-auto px-4 flex items-center justify-between py-1">
          <div>
            <Link to="/">
              <div className="flex items-center space-x-3 cursor-pointer">
                <img
                  src="/assets/images/logo.png"
                  loading="lazy"
                  className="xl:w-36 lg:w-36 w-32"
                  alt="Logo"
                />
              </div>
            </Link>
          </div>
          <div className="flex items-center gap-x-7 z-40">
            {userData?.user ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  className="flex items-center gap-x-1"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  <img
                    src="/assets/images/avater.png"
                    loading="lazy"
                    className="w-10"
                    alt="Logo"
                  />
                  {openMenu ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="16"
                      height="18"
                      className="mt-3"
                    >
                      <path
                        fill="currentColor"
                        d="M440.8 385.1l-41.9 41.9c-4.7 4.7-12.3 4.7-17 0L224 288.1 66.1 426c-4.7 4.7-12.3 4.7-17 0l-41.9-41.9c-4.7-4.7-4.7-12.3 0-17l208-208c4.7-4.7 12.3-4.7 17 0l208 208c4.7 4.7 4.7 12.3 0 17z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="16"
                      height="18"
                      className="mt-3"
                    >
                      <path
                        fill="currentColor"
                        d="M440.8 126.9L398.9 85c-4.7-4.7-12.3-4.7-17 0L224 249.9 66.1 85c-4.7-4.7-12.3-4.7-17 0l-41.9 41.9c-4.7 4.7-4.7 12.3 0 17l208 208c4.7 4.7 12.3 4.7 17 0l208-208c4.7-4.6 4.7-12.2 0-16.9z"
                      ></path>
                    </svg>
                  )}
                </button>
                <div
                  className={`absolute top-13 right-0 ${
                    openMenu
                      ? "overflow-hidden max-h-[240px] transition-all ease-in-out duration-300"
                      : "max-h-0 overflow-hidden transition-all ease-in-out duration-300"
                  } w-44 text-center`}
                >
                  <ul className="flex flex-col mt-1 px-2 py-2 font-medium bg-white border border-gray-300">
                    <div className="text-blue-500 gap-x-2 border-b pb-2 text-left pl-2">
                      <h4>{userData?.username}</h4>
                    </div>
                    <Link to="/profile">
                      <button
                        onClick={handleMenuClick}
                        className="px-2 pt-2 rounded cursor-pointer flex items-center gap-x-2 hover:text-blue-700 transition-all duration-300 ease-in-out"
                      >
                        <CgProfile />
                        <span>Profile</span>
                      </button>
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="py-1.5 text-white rounded-3xl bg-red-600 hover:bg-white border border-red-600 hover:text-red-600 cursor-pointer flex justify-center items-center gap-x-2 transition-all duration-300 ease-in-out mt-2 text-sm"
                    >
                      <HiLogout className="text-xl" />
                      Logout
                    </button>
                  </ul>
                </div>
              </div>
            ) : (
              <button
                onClick={() => navigate("/login")}
                type="button"
                className="text-white focus:ring-0 focus:outline-none font-medium rounded text-sm px-4 py-2 text-center bg-blue-600 hover:bg-white hover:text-[#2563eb] border border-[#2563eb] transition-all duration-300 ease-in-out"
              >
                Get started
              </button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
