import './App.css';
import React,{useState} from 'react';
import Login from "./Components/Pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Shared/Navbar/Navbar";
import Footer from "./Components/Shared/Footer/Footer";
import Profile from "./Components/Pages/Profile/Profile";
import Collections from './Components/Pages/Collections/Collections';

const App=()=> {
  const [updateUser, setUpdateUser] = useState(0);
  return (
    <BrowserRouter>
      <Navbar updateUser={updateUser} setUpdateUser={setUpdateUser} />
      <Routes>
        <Route path="/login" element={<Login updateUser={updateUser} setUpdateUser={setUpdateUser}/>} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/*" element={<Collections />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
