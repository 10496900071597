/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Select from "react-select";
import { FaCheck } from "react-icons/fa";
import useHeaders from "./../Headers/Headers";
import { isMobile } from "react-device-detect";
import MonoLoader from "./../Loader/MonoLoader";
import React, { useState, useEffect } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import { SuccessAlert } from "./../Alert/SuccessAlert";
import { FormateDate } from "./../../utils/FormateDate";
import useUserData from "./../../utils/Hook/UseUserData";
import { VscChromeClose, VscClose } from "react-icons/vsc";
import useInstitute from "./../../utils/Hook/UseInstitute";
import { productTypeOptions } from "./../SharedData/productTypeOptions";

export default function ForwardModal({ setShowForward, prevStatus }) {
  const headers = useHeaders();
  const { userData } = useUserData();
  const { institutes } = useInstitute();
  const [address, setAddress] = useState("");
  const [dateOptions, setDateOptions] = useState();
  const [timeOptions, setTimeOptions] = useState();
  const [teamLoading, setTeamLoading] = useState(false);
  const [instOptions, setInstOptions] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [remarksMessage, setRemarksMessage] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [teamTableOptions, setTeamTableOptions] = useState(null);
  const [forwardHistoryData, setForwardHistoryData] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState({
    label: "Aamartaka",
    value: 1,
  });

  // Get All Institutes
  useEffect(() => {
    if (!prevStatus?.id) return;
    const apiUrl = `/forword-insitutes/application/${prevStatus?.id}`;

    if (!apiUrl) return;

    axios
      .get(`${process.env.REACT_APP_API_PATH}${apiUrl}/`, headers)
      .then((resp) => {
        setInstOptions(resp?.data?.institutes);
      })
      .catch((error) => {});
  }, [prevStatus?.id]);

  // get All Team Options
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/group/?institute=${selectedInstitute?.value}`,
        headers
      )
      .then((resp) => {
        setTeamOptions(resp?.data?.results);
      })
      .catch((error) => {});
  }, [selectedInstitute?.value]);

  // Get Team Table Data By Selected Team
  useEffect(() => {
    if (selectedTeam?.value) {
      setTeamLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/profile/?no_page=true&user__groups=${selectedTeam?.value}`,
          headers
        )
        .then((resp) => {
          setTeamTableOptions(resp?.data);
          setTeamLoading(false);
        })
        .catch((error) => {
          setTeamLoading(false);
        });
    }
  }, [selectedTeam]);

  // Submit Selected Forward
  const handleForwardSubmit = async (item) => {
    let url = "/application-forwards/";

    let forwardData = {
      group: selectedTeam?.value,
      to_user: {
        user_id: item?.user || teamTableOptions[0]?.user,
        username: item?.username || teamTableOptions[0]?.username,
      },
      message: remarksMessage,
    };

    if (selectedTeam?.value === 101) {
      forwardData.application = prevStatus?.id;
      forwardData.product = selectedProduct;
      forwardData.institute = selectedBank;
    } else if (selectedTeam?.value === 113) {
      forwardData.application = prevStatus?.id;
      forwardData.schedule = {
        date: selectedDate,
        slot_id: selectedTime?.value,
        target_id: prevStatus?.id,
        target_type: 1,
        address: address,
      };
    } else {
      const commonData = {
        group: selectedTeam?.value,
        to_user: {
          user_id: item?.user,
          username: item?.username,
        },
        message: remarksMessage,
      };
      forwardData = {
        ...commonData,
        application: prevStatus?.id,
      };
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}${url}`,
        forwardData,
        headers
      );
      if (response) {
        SuccessAlert("Forwarded", "success");
        setShowForward(false);
      }
    } catch (error) {
      SuccessAlert("Forward Error", "error");
    }
  };

  // Get ForwardHistory Data By ID
  useEffect(() => {
    if (prevStatus?.id) {
      setHistoryLoading(true);
      let url = `/application-forwards/?application=${prevStatus?.id}`;

      axios
        .get(`${process.env.REACT_APP_API_PATH}${url}`, headers)
        .then((resp) => {
          setForwardHistoryData(resp?.data?.results);
        })
        .catch((error) => {
          setHistoryLoading(false);
        })
        .finally(() => {
          setHistoryLoading(false);
        });
    }
  }, [prevStatus?.id]);

  // Handle Forward Revert
  const handleRevert = (data) => {
    if (data?.id) {
      const requestData = {
        to_user: {
          user_id: data?.to_user?.user_id,
          username: data?.to_user?.username,
        },
        message: "Reverted",
      };
      let url = "/application-forwards/";

      axios
        .patch(
          `${process.env.REACT_APP_API_PATH}${url}${data?.id}/`,
          requestData,
          headers
        )
        .then((resp) => {
          SuccessAlert("Reverted", "success");
          setShowForward(false);
        })
        .catch((error) => {
          SuccessAlert("Revert Failed", "error");
        });
    }
  };

  // Schedule Date & Time
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/schedule-dates/`, headers)
      .then((resp) => {
        setDateOptions(resp?.data?.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectedDate) {
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/slot/available/?date=${selectedDate}`,
          headers
        )
        .then((resp) => {
          setTimeOptions(resp?.data?.results);
        })
        .catch((error) => {});
    }
  }, [selectedDate]);

  const options = dateOptions
    ? Object.keys(dateOptions)?.map((date) => ({
        value: date,
        label: `${dateOptions[date].day} (${dateOptions[date].week})`,
      }))
    : [];
  const defaultValue = options.length > 0 ? options[0] : null;

  // Stop BG Scroll
  useEffect(() => {
    document.body.classList.add("stop-scroll");
    return () => {
      document.body.classList.remove("stop-scroll");
    };
  }, []);

  return (
    <div>
      <div className="justify-center items-center flex fixed top-0 inset-1 z-50">
        <div
          className={`w-full my-6 mx-auto bg-white zoom-from-center flex justify-center max-h-[90vh] overflow-hidden ${
            isMobile ? "max-w-4xl" : "max-w-5xl"
          }`}
        >
          {/*content*/}
          <div className={`border-0 w-full pb-4`}>
            {/*header*/}

            <div
              className={`py-2 bg-slate-700 px-4 text-white flex items-center justify-between ${
                isMobile ? "mb-3" : "mb-3"
              }`}
            >
              <p
                className={`${
                  isMobile ? "text-lg" : "text-xl font-medium"
                } text-white`}
              >
                Collection Forward
              </p>
              <button
                className="ml-auto duration-700 float-right text-3xl leading-none font-medium h-auto"
                onClick={() => setShowForward(false)}
              >
                <VscClose className="text-red-500" />
              </button>
            </div>

            {/*body*/}
            <div className="h-[80vh] overflow-auto">
              <div
                className={`${
                  isMobile ? "px-2 mx-1 pt-2 pb-3" : "py-3 my-2 mx-4 px-6 pb-5"
                } bg-white rounded border border-gray-300 max`}
              >
                <div>
                  <div
                    className={`${
                      isMobile ? "block" : "grid grid-cols-2 gap-x-3"
                    }`}
                  >
                    <div className={`w-full text-sm my-1`}>
                      <label>Institute</label>
                      <Select
                        isSearchable={false}
                        placeholder="Select Institute"
                        className="text-sm text-black w-full whitespace-nowrap"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={instOptions?.map((ins) => ({
                          label: ins?.name,
                          value: ins?.id,
                        }))}
                        defaultValue={{
                          label: selectedInstitute?.label,
                          value: selectedInstitute?.value,
                        }}
                        onChange={(selectedOption) =>
                          setSelectedInstitute(selectedOption)
                        }
                      />
                    </div>

                    <div className={`w-full text-sm my-1`}>
                      <label>Team</label>
                      <Select
                        isSearchable={false}
                        placeholder="Select A Team"
                        className="text-sm text-black w-full whitespace-nowrap"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={teamOptions?.map((option) => ({
                          label: option?.name,
                          value: option?.id,
                        }))}
                        onChange={(selectedOption) =>
                          setSelectedTeam(selectedOption)
                        }
                      />
                    </div>

                    {selectedTeam?.value === 101 && (
                      <div className={`w-full text-sm my-1`}>
                        <label>Select Bank</label>
                        <Select
                          isSearchable={false}
                          placeholder="Select Bank"
                          className="text-sm text-black w-full whitespace-nowrap"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={institutes?.map((institute) => ({
                            value: institute?.id,
                            label: institute?.name,
                          }))}
                          onChange={(selectedOption) =>
                            setSelectedBank(selectedOption?.value)
                          }
                        />
                      </div>
                    )}

                    {selectedTeam?.value === 101 && (
                      <div className={`w-full text-sm my-1`}>
                        <label>Product</label>
                        <Select
                          isSearchable={false}
                          isDisabled={!selectedBank}
                          placeholder="Select Product"
                          className="text-sm text-black w-full whitespace-nowrap"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={productTypeOptions?.map((product) => ({
                            label: product?.name,
                            value: product?.value,
                          }))}
                          onChange={(selectedOption) =>
                            setSelectedProduct(selectedOption?.value)
                          }
                        />
                      </div>
                    )}

                    {selectedTeam?.value === 113 && (
                      <div className={`w-full text-sm my-1`}>
                        <p>Schedule Date</p>
                        <Select
                          required
                          isSearchable={false}
                          placeholder="Select Date"
                          className="text-[15px] text-black w-full whitespace-nowrap text-sm"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          // styles={CustomStyles2}
                          options={options}
                          defaultValue={defaultValue}
                          onChange={(selectedOption) =>
                            setSelectedDate(selectedOption?.value)
                          }
                        />
                      </div>
                    )}

                    {selectedTeam?.value === 113 && (
                      <div className="w-full text-sm my-1">
                        <p className="text-sm">Schedule Time</p>
                        <Select
                          required
                          isSearchable={false}
                          placeholder="Select Time"
                          className="text-sm text-black w-full whitespace-nowrap"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          // styles={CustomStyles2}
                          options={timeOptions?.map((time) => ({
                            label: `${time?.start_time} - ${time?.end_time}`,
                            value: time?.id,
                          }))}
                          onChange={(options) => setSelectedTime(options)}
                        />
                      </div>
                    )}

                    {selectedTeam?.value === 113 && (
                      <div className="w-full text-sm my-1">
                        <p className="text-sm">Schedule Location</p>
                        <input
                          type="text"
                          placeholder="Schedule Location"
                          className="border w-full px-2 py-2 text-sm border-gray-300 focus:outline-0 focus:border-[#01a7ff]"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="w-full text-sm my-1">
                      <p className="text-sm">Remarks</p>
                      <textarea
                        className="border rounded-[2px] px-2 pt-1 w-full text-sm focus:outline-[#01a7ff]"
                        placeholder="Write Remarks..."
                        rows={2}
                        onChange={(e) => setRemarksMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={`w-full`}>
                {/* Team Table Data */}
                {teamLoading ? (
                  <div className="pb-10">
                    <MonoLoader />
                  </div>
                ) : (
                  <>
                    {teamTableOptions?.length > 0 && (
                      <div
                        className={`overflow-x-auto ${
                          isMobile ? "mx-1 mt-2" : "mx-4"
                        } bg-white`}
                      >
                        <div className="inline-block min-w-full overflow-hidden">
                          <table className="min-w-full leading-normal overflow-scroll">
                            <tbody>
                              {teamTableOptions?.map((item, index) => (
                                <>
                                  <tr key={index + 1}>
                                    <td
                                      className={`${
                                        isMobile ? "px-4 py-2" : "p-2"
                                      } border text-center`}
                                    >
                                      <p className="text-gray-600 whitespace-nowrap text-center text-[13px]">
                                        {item?.full_name}
                                      </p>
                                    </td>
                                    <td
                                      className={`${
                                        isMobile ? "px-4 py-2" : "p-2"
                                      } border text-center`}
                                    >
                                      <p className="whitespace-nowrap text-center flex justify-center items-center">
                                        {item?.availability ? (
                                          <FaCheck className="text-[#01a7ff] text-sm text-center" />
                                        ) : (
                                          <VscChromeClose className="text-red-500 text-sm text-center" />
                                        )}
                                      </p>
                                    </td>

                                    <td
                                      className={`${
                                        isMobile ? "px-4 py-2" : "p-2"
                                      } border text-center`}
                                    >
                                      <p className="text-gray-600 whitespace-nowrap text-center text-[13px]">
                                        {item?.file_in_hand}
                                      </p>
                                    </td>
                                    <td
                                      className={`${
                                        isMobile ? "px-4 py-2" : "p-2"
                                      } border text-center`}
                                    >
                                      <p className="text-gray-600 whitespace-nowrap text-center text-[13px]">
                                        {item?.member_type}
                                      </p>
                                    </td>
                                    <td
                                      className={`${
                                        isMobile ? "px-4 py-2" : "p-2"
                                      } border text-center mx-auto`}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleForwardSubmit(item);
                                        }}
                                        className="text-white text-[13px] hover:text-sky-600 border border-[#01a7ff] rounded-[1px] bg-[#01a7ff] px-2 py-1.5 hover:bg-white transition-all duration-300 ease-in-out whitespace-nowrap tracking-tighter flex items-center gap-x-2 mx-auto"
                                      >
                                        <RiShareForwardFill />
                                        <span>Forward</span>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* Forward History Table */}
                <div className="bg-white">
                  {historyLoading ? (
                    <MonoLoader />
                  ) : (
                    <div className={`${isMobile ? "px-2" : "px-4"} mb-5`}>
                      <h4 className="text-center text-lg text-gray-600 pt-2">
                        Application Forward History
                      </h4>
                      {forwardHistoryData?.length > 0 ? (
                        <div
                          className={`${
                            isMobile ? "pb-3 overflow-auto" : " pb-5"
                          }`}
                        >
                          <table className="min-w-full leading-normal overflow-scroll mt-3 mb-4">
                            <thead>
                              <tr>
                                <th className="p-2 text-[13px] border border-gray-300 text-center font-medium text-gray-700 whitespace-nowrap w-44">
                                  Date
                                </th>
                                <th className="p-2 text-[13px] border border-gray-300 text-center font-medium text-gray-700 whitespace-nowrap">
                                  From User
                                </th>
                                <th className="p-2 text-[13px] border border-gray-300 text-center font-medium text-gray-700 whitespace-nowrap">
                                  To User
                                </th>
                                <th className="p-2 text-[13px] border border-gray-300 text-center font-medium text-gray-700 whitespace-nowrap">
                                  In Hand Time
                                </th>
                                <th className="p-2 text-[13px] border border-gray-300 text-center font-medium text-gray-700 whitespace-nowrap">
                                  Remarks
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {forwardHistoryData?.map((data, index) => (
                                <tr key={index + 1}>
                                  <td className="p-2 text-[13px] border border-gray-300 bg-white text-center w-44 whitespace-nowrap text-gray-600">
                                    {FormateDate(data?.created_at)}
                                  </td>
                                  <td className="p-2 text-[13px] border-b border-b-gray-300 bg-white text-center text-gray-600 flex items-center">
                                    <p className="flex-1">
                                      {data?.from_user?.username}
                                    </p>
                                    {index === 0 &&
                                      data?.from_user?.user_id ===
                                        userData?.user &&
                                      data?.message !== "Reverted" && (
                                        <button
                                          onClick={() => handleRevert(data)}
                                          type="button"
                                          className="text-[13px] text-white border border-red-500 bg-red-500 px-2 py-0.5 rounded hover:bg-white hover:text-red-600 transition-all duration-300 ease-in-out"
                                        >
                                          Revert
                                        </button>
                                      )}
                                  </td>
                                  <td className="p-2 text-[13px] border border-gray-300 bg-white text-center whitespace-nowrap text-gray-600">
                                    {data?.to_user?.username}
                                  </td>
                                  <td className="p-2 text-[13px] border border-gray-300 bg-white text-center whitespace-nowrap text-gray-600">
                                    {data?.in_hand_time}
                                  </td>
                                  <td className="p-2 text-[13px] border border-gray-300 bg-white text-center whitespace-nowrap text-gray-600">
                                    {data?.message}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p className="text-sm text-center text-gray-400">
                          {" "}
                          No History Found{" "}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
