import axios from "axios";
import Swal from "sweetalert2";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import useHeaders from "./../../Shared/Headers/Headers";
import useUserData from "./../../utils/Hook/UseUserData";
import OptionSpinner from "./../../Shared/Loader/OptionSpinner";
import { SuccessAlert } from "./../../Shared/Alert/SuccessAlert";

const Profile = () => {
  // const router = useRouter();
  const headers = useHeaders();
  const [file, setFile] = useState(null);
  const [edit, setEdit] = useState(false);
  const [isReload, setIsReload] = useState(1);
  const [imageLoading, setImageLoading] = useState(false);
  const { userData, updateUser, setUpdateUser } = useUserData();
  const [editUser, setEditUser] = useState({
    username: "",
    email: "",
    mobile_number: "",
  });

  // All Event Handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      const newData = { ...editUser };
      newData[name] = value;
      setEditUser(newData);
    }
  };

  // Handle Profile Image Upload
  const handleImageUpload = async () => {
    setImageLoading(true);
    const imageForm = new FormData();
    imageForm.append("profile_image", file);
    try {
      const res = await axios.patch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/agentprofile/${userData?.id}/`,
        imageForm,
        headers
      );

      if (res?.status === 200) {
        localStorage.setItem("salesUser", JSON.stringify(res?.data));
        setIsReload(isReload + 1);
        setImageLoading(false);
        SuccessAlert("Image Updated", "success");
      }
    } catch (error) {
      setImageLoading(false);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("salesUser");
        localStorage.removeItem("token");
        SuccessAlert("Logout Successful", "success");
        setUpdateUser(updateUser + 1);
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <div className="bg-blue-50 min-h-screen">
        <div className="max-w-3xl mx-auto px-4 pt-10 pb-5">
          <div className="pb-5 bg-white px-6 rounded border border-gray-300">
            <div className="pt-2 pb-4 flex justify-between items-center">
              <div className="text-lg font-bold text-gray-700 leading-tight">
                Your Profile
              </div>
              <button
                onClick={() => handleLogout()}
                className="w-32 bg-red-600 text-white px-4 py-1.5 border border-red-600 rounded-full hover:bg-white hover:text-red-600 transition-all duration-300 ease-in-out mt-3"
              >
                Logout
              </button>
            </div>
            <div className="mb-5 text-center">
              <div className="mx-auto w-32 h-32 border rounded-full relative bg-gray-100 mb-4 shadow-inset">
                <img
                  src="/assets/images/avater.png"
                  className="object-cover w-full h-32 rounded-full"
                  alt="Profile"
                  loading="lazy"
                />
              </div>

              <div className="flex w-full items-center justify-center">
                <label
                  className={`w-full flex flex-col items-center px-4 py-2 bg-white rounded uppercase cursor-pointer ${
                    file && "hover:bg-transparent"
                  } transition-all ease-in-out duration-300 text-[#2563eb]`}
                >
                  {file ? (
                    <div>
                      {imageLoading ? (
                        <div className="h-16 w-48 flex justify-center items-center">
                          <OptionSpinner />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={file}
                            alt="profile"
                            className="h-24 w-48 mt-1 mb-3 rounded"
                            loading="lazy"
                          />
                          <div className="border border-[#2563eb] bg-[#2563eb] text-white capitalize text-sm text-center px-1 py-0.5 rounded-full hover:bg-white hover:text-[#2563eb] transition-all duraion-300 ease-in-out">
                            <span className="leading-normal">Change</span>
                            <input
                              onChange={handleImageUpload}
                              type="file"
                              className="hidden"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {imageLoading ? (
                        <div className="h-16 w-48 flex justify-center items-center">
                          <OptionSpinner />
                        </div>
                      ) : (
                        <div className="cursor-pointer inine-flex justify-between items-center focus:outline-none border border-blue-600 py-2 px-4 rounded-lg text-left text-white bg-blue-600 hover:bg-white hover:text-blue-600 font-medium transition-all duration-300 ease-in-out">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <rect
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                              stroke="none"
                            ></rect>
                            <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                            <circle cx="12" cy="13" r="3" />
                          </svg>
                          <span className="text-base leading-normal">
                            Browse Photo
                          </span>

                          <input
                            onChange={handleImageUpload}
                            type="file"
                            className="hidden"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </label>
              </div>

              <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                Click to add profile picture
              </div>

              <input
                name="photo"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleChange}
                type="file"
              />
            </div>
            <div>
              <div className="flex justify-between items-center mb-5">
                {edit ? (
                  <h4 className="text-lg font-semibold">
                    Update Your Profile{" "}
                  </h4>
                ) : (
                  <h4 className="text-lg font-semibold">Profile Information</h4>
                )}
                {edit ? (
                  <button
                    onClick={() => setEdit(false)}
                    className="text-white bg-red-600 border border-red-600 hover:text-red-600 hover:bg-white transition-all duration-300 ease-in-out px-3 py-1.5 rounded-full flex items-center gap-x-2"
                  >
                    <VscChromeClose />
                    <span>Cancel Edit</span>
                  </button>
                ) : (
                  <button
                    onClick={() => setEdit(true)}
                    className="text-white bg-blue-600 border border-blue-600 hover:text-blue-600 hover:bg-white transition-all duration-300 ease-in-out px-3 py-1.5 rounded-full flex items-center gap-x-2"
                  >
                    <FaEdit />
                    <span>Edit Profile</span>
                  </button>
                )}
              </div>
              {edit ? (
                <form className="flex flex-col mx-2">
                  <div className="mb-3">
                    <h2 className="text-gray-600 text-sm text-left">
                      User Name
                    </h2>
                    <input
                      type="text"
                      name="username"
                      placeholder="Enter Username"
                      defaultValue={userData?.username}
                      onChange={handleChange}
                      className="border px-2 py-2 border-gray-300 rounded mt-1 w-full focus:outline-blue-600"
                    />
                  </div>

                  <div className="mb-3">
                    <h2 className="text-gray-600 text-sm text-left">Email</h2>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      defaultValue={userData?.email}
                      onChange={handleChange}
                      className="border px-2 py-2 border-gray-300 rounded mt-1 w-full focus:outline-blue-600"
                    />
                  </div>

                  <div className="mb-3">
                    <h2 className="text-gray-600 text-sm text-left">Phone</h2>
                    <input
                      required
                      type="number"
                      name="phone_number"
                      placeholder="Enter Your Number"
                      maxLength={11}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      defaultValue={userData?.mobile_number}
                      onChange={handleChange}
                      className="border px-2 py-2 border-gray-300 rounded mt-1 w-full focus:outline-blue-600"
                    />
                  </div>
                  <div className="flex items-center gap-x-4">
                    <button
                      type="submit"
                      className="bg-[#2563eb] text-white px-5 py-1.5 border border-[#2563eb] rounded hover:bg-white hover:text-blue-600 transition-all duration-300 ease-in-out mt-3"
                    >
                      <span> Update</span>
                    </button>
                    <button
                      type="reset"
                      onClick={() => setEdit(false)}
                      className="bg-red-600 text-white px-5 py-1.5 border border-red-600 rounded hover:bg-white hover:text-red-600 transition-all duration-300 ease-in-out mt-3"
                    >
                      <span> Cancel </span>
                    </button>
                  </div>
                </form>
              ) : (
                <div className="flex flex-col mx-2">
                  <div className="mb-3">
                    <h2 className="text-gray-600 text-sm text-left">
                      User Name
                    </h2>
                    <div className=" px-2 py-2">
                      <p>{userData?.username} </p>
                    </div>
                  </div>

                  <div className="mb-3">
                    <h2 className="text-gray-600 text-sm text-left">Email</h2>
                    <div className="px-2 py-2">
                      <p> {userData?.email} </p>
                    </div>
                  </div>

                  <div className="mb-3">
                    <h2 className="text-gray-600 text-sm text-left">Phone</h2>
                    <div className="px-2 py-2">
                      <p>{userData?.mobile_number}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
