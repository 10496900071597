/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation in React Router
import { FaEye, FaLock } from 'react-icons/fa';
import Loader from './../../Shared/Loader/Loader';
import React, { useState, useEffect } from 'react';
import { FaEyeSlash, FaUser } from 'react-icons/fa6';
import useUserData from './../../utils/Hook/UseUserData';
import { SuccessAlert } from './../../Shared/Alert/SuccessAlert';
// Components

const Login = ({ updateUser,setUpdateUser }) => {
  const navigate = useNavigate(); // Use navigate for routing
  const { userData } = useUserData();
  const oldUser = userData?.user;
  const [loginData, setLoginData] = useState({
    phone: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState('password');

  // Login Function Here
  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const data = {
      username: loginData.phone,
      password: loginData.password,
    };
  
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_PATH}/collection-user-login/`,
        data
      );
  
      if (res.status === 200 && res.data.data.token) {
        const { token, user_data: userData } = res.data.data;
        localStorage.setItem('token', token);
        localStorage.setItem('salesUser', JSON.stringify(userData));
        SuccessAlert('Login Successful', 'success');
        setUpdateUser(updateUser+1)
        navigate('/');
      } else if (res.data.error) {
        SuccessAlert(res.data.error, 'error');
      }
    } catch (error) {
      console.error("Login Error: ", error);
      SuccessAlert(error.message || 'Something went wrong', 'error');
    } finally {
      setIsLoading(false);
      e.target.reset();
    }
  };
  

  useEffect(() => {
    if (oldUser) {
      navigate('/');
      SuccessAlert('Already Logged in', 'error');
    }
  }, [oldUser, navigate]); // Add navigate to dependency array

  return (
    <div>
   <div className="h-screen flex flex-col">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="h-screen w-full flex justify-center items-center bg-gray-100">
          <div className="bg-gradient-to-tr from-indigo-600 to-indigo-700 2xl:w-1/2 xl:w-[70%] w-[60%] flex items-center mx-5 rounded border shadow-style">
            <div className="w-1/2 flex flex-col justify-center items-center text-white">
              <h1 className="text-2xl">Hello</h1>
              <p className="2xl:text-4xl text-2xl font-extrabold">Welcome!</p>
              <h1 className="text-2xl">to</h1>
              <img
                src="/assets/images/logo.png"
                alt="logo"
                className="my-3 2xl:w-60 w-52"
                loading="lazy"
              />
            </div>
            <div className="bg-white w-full md:w-1/2 flex flex-col items-center py-32 px-8">
              <h3 className="2xl:text-3xl text-2xl font-semibold mb-4">Please Login</h3>
              <form
                onSubmit={handleLogin}
                className="w-full flex flex-col justify-center"
              >
                <div className="mb-4 relative">
                  <input
                    autoComplete="off"
                    required
                    name="phone"
                    type="text"
                    placeholder="Enter Username"
                    onChange={handleStateChange}
                    className="w-full pr-3 py-2.5 pl-9 bg-white rounded border border-gray-300 focus:outline-blue-600 text-sm"
                  />
                  <FaUser className="absolute top-3.5 left-3 text-gray-500" />
                </div>
                <div className="mb-4 relative">
                  <input
                    autoComplete="off"
                    required
                    name="password"
                    onChange={handleStateChange}
                    type={inputType}
                    placeholder="Password"
                    className="w-full pr-3 py-2.5 pl-9 bg-white rounded border border-gray-300 focus:outline-blue-600 text-sm"
                  />
                  <FaLock className="absolute top-3.5 left-3 text-gray-500" />
                  {inputType === "password" ? (
                    <button
                      type="button"
                      onClick={() => setInputType("text")}
                    >
                      <FaEye className="absolute top-3.5 right-3 text-gray-500 text-lg" />
                    </button>
                  ) : (
                    <button type="button" onClick={() => setInputType("password")}>
                      <FaEyeSlash className="absolute top-3.5 right-3 text-gray-500 text-xl" />
                    </button>
                  )}
                </div>
                <button
                  type="submit"
                  className="text-white bg-blue-600 border border-blue-600 hover:text-blue-600 hover:bg-white font-medium focus:outline-none rounded px-3 py-2.5 transition-all duration-300 ease-in-out text-sm"
                >
                  Login
                </button>
                <button className="flex justify-end mt-4 text-sm text-red-500">
                  Forgot Password?
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Login;
