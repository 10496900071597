import React from "react";
import { VscChromeClose } from "react-icons/vsc";

const TableInfoSearch = ({
  mobile,
  setMobile,
  handleReset,
  placeholder,
  setOpenFilter,
  setShowClearButton,
  handleSearchSubmit,
  handleMobileInputChange,
}) => {
  return (
    <div>
      <div className="w-full mb-1 mt-2 relative">
        <input
          required
          type="text"
          value={mobile}
          placeholder={placeholder}
          onChange={handleMobileInputChange}
          onFocus={() => setShowClearButton(false)}
          className="-mr-0.5 block w-full rounded-l border px-3 py-1.5 text-[13px] font-normal leading-[1.6] outline-none transition duration-300 ease-in-out focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)]"
        />
        {mobile && (
          <button
            type="button"
            onClick={() => setMobile("")}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <VscChromeClose className="text-red-500 hover:text-red-600" />
          </button>
        )}
      </div>
      <div className="pb-2 pt-0.5 flex items-center gap-x-3">
        <button
          type="button"
          onClick={() => handleSearchSubmit(false)}
          className="w-full text-[13px] border border-gray-300 bg-gray-100 px-5 py-1 font-normal hover:bg-gray-300 hover:border-gray-400 transition-all duration-300 ease-in-out"
        >
          Search
        </button>
        {mobile ? (
          <button
            type="button"
            onClick={() => handleReset()}
            className="w-full text-[13px] border border-red-300 bg-red-100 px-5 py-1 font-normal hover:bg-red-300 hover:border-red-400 transition-all duration-300 ease-in-out"
          >
            Reset
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setOpenFilter(null)}
            className="w-full text-[13px] border border-red-300 bg-red-100 px-5 py-1 font-normal hover:bg-red-300 hover:border-red-400 transition-all duration-300 ease-in-out"
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default TableInfoSearch;
