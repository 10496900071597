import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation for current path

import { useEffect, useState } from 'react';
import Loader from './../Loader/Loader';

const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!token) {
    navigate('/login', { replace: true, state: { from: location } });
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoute;
