export const ConvertDate=(dateString)=> {
    // Create a new Date object from the dateString
    const date = new Date(dateString);
  
    // Extract the day, month, and year from the Date object
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
  
    // Format the date as dd/mm/yyyy
    const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
  }