import axios from "axios";
import { useEffect, useState } from "react";
import useHeaders from './../../Shared/Headers/Headers';
import useUserData from './UseUserData';


const useInstitute = () => {
  const headers = useHeaders();
  const { userData } = useUserData();
  const [institutes, setInstitutes] = useState([]);
  const [instLoading, setInstLoading] = useState(false);

  useEffect(() => {
    setInstLoading(true);
    if (userData?.user) {
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/all-partner-institutes/`,
          headers
        )
        .then((res) => {
          setInstitutes(res?.data);
          setInstLoading(false);
        })
        .catch((error) => {
          setInstLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user]);
  return { institutes, setInstitutes, instLoading };
};

export default useInstitute;
