
import React from "react";

const NoDataFound = () => {
  return (
    <div className="pb-8">
      <div className="text-center mt-8 bg-white w-72 mx-auto px-3 pb-5 pt-3 rounded-md border border-gray-200">
        <div>
          <img
            src="/assets/images/empty.svg"
            loading="lazy"
            alt="empty"
            className="w-10 mx-auto"
          />
        </div>
        <div className="mt-1 text-[13px]">No Data Found</div>
        <div className="text-gray-400 text-[12px]">
          This Message is For Notify You
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;
