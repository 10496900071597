/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Select from "react-select";
import { VscClose } from "react-icons/vsc";
import { FaAngleRight } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import useHeaders from "./../Headers/Headers";
import MonoLoader from "./../Loader/MonoLoader";
import React, { useState, useEffect } from "react";
import OptionSpinner from "./../Loader/OptionSpinner";
import { SuccessAlert } from "./../Alert/SuccessAlert";
import { CollectionStatus } from "./../SharedData/CollectionStatus";
import CollectionUpdateHistoryTableSubmitted from "./../SharedComponents/CollectionUpdateHistoryTableSubmitted";

export default function CollectionStatusChangeModal({
  isRemarks,
  prevStatus,
  collectionData,
  setCollectionData,
  setShowStatusChangeModal,
}) {
  const headers = useHeaders();
  const [remarks, setRemarks] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [updateHistoryData, setUpdateHistoryData] = useState([]);

  const handleUpdateStatus = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    const data = {
      application: prevStatus?.id,
      collection_type: selectedStatus
        ? selectedStatus.value
        : prevStatus?.collection_status,
      ...(isRemarks && { message: remarks }),
    };
    if (prevStatus?.id) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/application-collection-status/`,
          data,
          headers
        );
        if (response) {
          setSubmitLoading(false);
          SuccessAlert("Status Updated", "success");
          setShowStatusChangeModal(false);
          const updatedData = collectionData?.map((item) =>
            item?.id === response?.data?.application
              ? {
                  ...item,
                  collection_status: response?.data?.collection_type,
                  collection_message: response?.data?.message,
                }
              : item
          );
          setCollectionData(updatedData);
        }
      } catch (error) {
        SuccessAlert(error?.response?.data?.message, "error");
        setSubmitLoading(false);
      }
    } else {
      SuccessAlert("Data Not Found", "error");
    }
  };

  // Get History Data
  useEffect(() => {
    if (prevStatus?.id) {
      setHistoryLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_PATH}/application-collection-status/?application=${prevStatus?.id}`,
          headers
        )
        .then((resp) => {
          setUpdateHistoryData(resp?.data?.results);
          setHistoryLoading(false);
        })
        .catch((error) => {
          setHistoryLoading(false);
        });
    }
  }, [prevStatus?.id]);

  // Stop BG Scroll
  useEffect(() => {
    document.body.classList.add("stop-scroll");
    return () => {
      document.body.classList.remove("stop-scroll");
    };
  }, []);

  return (
    <div>
      <div className="justify-center items-center flex fixed top-0 inset-1 z-50">
        <div
          className={`w-full my-6 mx-auto bg-white zoom-from-center ${
            isMobile
              ? "max-w-3xl max-h-[95vh] overflow-hidden"
              : "max-w-5xl max-h-[90vh] overflow-auto flex justify-center pb-10"
          }`}
        >
          {/*content*/}
          <div className={`border-0 flex flex-col w-full pb-4`}>
            {/*header*/}
            <div
              className={`py-2 bg-slate-800 px-4 text-white flex items-center justify-between mb-3`}
            >
              <p className={`${isMobile ? "text-lg" : "text-xl"} text-white`}>
                Update Status
              </p>
              <button
                className="ml-auto duration-700 float-right text-3xl leading-none font-semibold h-auto"
                onClick={() => setShowStatusChangeModal(false)}
              >
                <VscClose className="text-red-500" />
              </button>
            </div>
            {/*body*/}

            <div>
              <div
                className={`${
                  isMobile ? "px-2 py-1 mx-2" : "py-3 my-2 mx-3 px-5"
                } bg-white rounded border border-gray-300`}
              >
                <form onSubmit={handleUpdateStatus}>
                  <div
                    className={`${
                      isMobile ? "block mt-1" : "flex justify-between gap-x-4"
                    }`}
                  >
                    <div className={`w-full`}>
                      <label className="block mb-0.5 text-gray-600 text-sm">
                        Select Status
                      </label>
                      <Select
                        required
                        isSearchable={false}
                        placeholder="Select Status"
                        className="my-1 text-sm text-black w-full whitespace-nowrap"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        maxMenuHeight={140}
                        options={CollectionStatus}
                        defaultValue={
                          prevStatus?.collection_status
                            ? {
                                label: prevStatus?.collection_status,
                                value: prevStatus?.collection_status,
                              }
                            : "Select Status"
                        }
                        onChange={(selectedOption) =>
                          setSelectedStatus(selectedOption)
                        }
                      />
                    </div>
                    {isRemarks && (
                      <div className="w-full">
                        <label className="mb-0.5 text-gray-700 block text-sm">
                          Customer Remarks
                        </label>
                        <textarea
                          required
                          rows={1}
                          name="remarks"
                          placeholder="Remarks Change Reason"
                          onChange={(e) => setRemarks(e.target.value)}
                          defaultValue={prevStatus?.collection_message}
                          className={`border border-gray-300 w-full px-2 ${
                            isMobile ? "py-[6.5px]" : "py-[8.8px]"
                          } text-sm focus:outline-none focus:border-[#2563eb]`}
                        ></textarea>
                      </div>
                    )}
                  </div>

                  <div
                    className={`w-full flex justify-end ${
                      isMobile ? "pb-1" : ""
                    }`}
                  >
                    {submitLoading ? (
                      <button
                        disabled
                        type="button"
                        className={`w-36 flex justify-center items-center py-2 px-4 bg-white border border-[#01a7ff] mt-2 ${
                          isMobile ? "text-sm" : "text-md"
                        }`}
                      >
                        <OptionSpinner />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`flex items-center py-1.5 px-5 bg-[#01a7ff] border border-[#01a7ff] text-white hover:bg-white hover:text-sky-600 transition-all duration-300 ease-in-out mt-1 ${
                          isMobile ? "text-sm mb-1" : "text-md"
                        }`}
                      >
                        <span>Update</span>
                        <FaAngleRight />
                      </button>
                    )}
                  </div>
                </form>
              </div>
              <div>
                {historyLoading ? (
                  <div className={`pb-10 flex justify-center items-center`}>
                    <MonoLoader />
                  </div>
                ) : (
                  <div>
                    <h4
                      className={`${
                        isMobile ? "text-md pt-2" : "text-lg "
                      } font-medium text-center text-gray-700`}
                    >
                      File Collection History
                    </h4>
                    <div
                      className={`${
                        isMobile ? "mx-2 py-1 mt-2" : "pt-3 mb-2 mx-3"
                      } rounded overflow-x-auto overflow-y-scroll max-h-[50vh]`}
                    >
                      {/* History Table */}
                      <div className="inline-block min-w-full overflow-hidden">
                        {updateHistoryData?.length > 0 ? (
                          <CollectionUpdateHistoryTableSubmitted
                            updateHistoryData={updateHistoryData}
                          />
                        ) : (
                          <p className="text-center text-gray-500 text-sm">
                            No History Found
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
