import React from "react";
function MonoLoader() {

  return (
    <div className="mt-20 flex justify-center items-center">
      <span className="loader"></span>
    </div>
  );
}

export default MonoLoader;
