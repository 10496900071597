/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

const useUserData = () => {
  const [userData, setUserData] = useState({});
  const [updateUser, setUpdateUser] = useState(0);

  const getUserDataFromLocalStorage = () => {
    const userDataStr = localStorage.getItem("salesUser");
    if (userDataStr) {
      const userDataObj = JSON.parse(userDataStr);
      setUserData(userDataObj);
    } else {
      setUserData({});
    }
  };
  useEffect(() => {
    getUserDataFromLocalStorage();
  }, [updateUser]);

  return { userData, setUpdateUser, updateUser };
};

export default useUserData;
